<script lang="ts" setup>
import uEmojiParser from 'universal-emoji-parser'

const props = defineProps<{
  icon?: string | null
  sizeClass?: string | null
  textSizeClass?: string | null
}>()

const parsedIcon = computed(() => {
  return uEmojiParser.parse(props.icon || '✨', { parseToHtml: false, parseToUnicode: true })
})
</script>

<template>
  <div class="flex items-center justify-center rounded-md bg-base-200 ring  ring-primary/10 ring-offset-2 ring-offset-base-100" :class="sizeClass || 'size-8'">
    <span :class="textSizeClass || 'text-xl'">{{ parsedIcon }}</span>
  </div>
</template>
